/// <reference path="../mixins.scss" />
$table-header-bg: #e2e2e2;
$table-row-even-bg: #f4f4f4;
$table-row-odd-bg: white;


.edit-price{
    padding-bottom: 100px;

    .save-banner {
      position: fixed;
      bottom: 10px;
      z-index: 10000;
      max-width: 800px;
      left: 50%;
      transform: translateX(-50%);
      ol{
        width: 100%;
        padding: 0 1rem
      }
      p + p{
        margin-top: 1rem;
      }
    }
  
    .filters{
        h1{
          padding: 0;
          display: block;
          margin-top: var(--gutter);
        }
    }
    .price-list, .missing-price-list{
        margin: var(--gutter) 0;
    }
  
    .price-row.active{
        border: 1px solid darken($table-row-even-bg, 30%);
        background-color: darken($table-row-even-bg, 2%) ;
        .description .value{
            //color: var(--color-danger);
        }
        
    }
    .filter{
      display: flex;
      flex-flow: row wrap;
      gap: 20px;
    }
    
}
.profitcenter-picker{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  //justify-content: space-between;
  gap: 5px;
  .badge{
    margin: 0;
  }
}
.price-list{
    margin-top: 20px;
    padding: 0;
    display: table;
    border-collapse: collapse;
    
  }
  
  .price-row{
    
    display: table-row;

    background-color: $table-row-odd-bg;
    
    &:nth-child(even){
      background-color: $table-row-even-bg;
      
    }
    .excluded-cb{
      width: 1%;
    }
    
    &.has-edits {
        background-color: #aed0ed;
    }
    > div {
     // width: 10%;
     display: table-cell;
     vertical-align: middle;
     padding: 10px;
     position: relative;
    .label {
      display: none;
      @include for-phone-only{
        display: block;
        font-size: small;
        font-weight: bold;
        color: #5f5f5f;
        margin: -3px 0 -3px;
      }
    }
    }
    .codes{
      cursor: pointer;
    }
    > *{
       flex-flow:row;
    }
    &.excluded {
      > *:not(:first-child){ 
        opacity: 0.5;
        text-decoration: line-through;
      }
    }
    
    .description {
      width: 30%;
    }
    .category{
      width: 20%;  }
    
    .default-price .value{
      opacity: 0.6;
      font-weight: bold;

    }
    .new-price{
      width: 70px;
      //align-self: flex-end;
      //font-weight: bold;
      //color: green;
      input{
        width: 100%;
        text-align: center;
        font-weight: bold;
      }
    }

    /*removes the number thingy*/
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }input[type="number"] {
        -moz-appearance: textfield;
    }

    &.head-row{
        background-color: $table-header-bg;

        .label{
            display: block;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin: 5px 0;
        }
        @include for-phone-only {
            display: none;
        }
    }
    .group-children{
      position: absolute;
      background-color: white;
      //padding: 10px 0;
      z-index: 1000;
      list-style: none;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      min-width: 320px;
      width: max-content;
      max-width: 500px;
      
      li{
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 20px;
        &:not(:first-child){
          border-top: 1px solid rgb(155, 155, 155);
        }
      }
    }
  //position: relative;
  
}
.price-footer{
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  box-shadow: var(--form-box-shadow);
  border-top: 1px solid rgb(180, 180, 180);
  .btn-group{
    justify-content: center;
    gap: 20px;
    a{
      text-decoration: none;
    }
  }
}
@include for-phone-only {
  .price-row{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .description {
      width: 50%;
    }
    .category {
      width: 30%;
    }
    .default-price, .new-price{
      width: 20%;
    }
  }
}

