/*.tabs-container{
    background-color: var(--background-color-navbar);
}*/
$nav-tab-margin: var(--gutter);
.nav-tabs{
    margin: 0 calc(var(--gutter) - var(--gutter) * 2);
    display: flex;
    gap: 5px;
    list-style: none;
    padding: 0 var(--gutter) 0;
    border-bottom: 1px solid #C9C9C9;
    
    .nav-link {
        background-color: transparent;
        border: none;
        padding: 10px 20px 5px;
        border-radius: 5px 5px 0 0 ;
        //color: white;
        font-weight: bold;
        &.active{
            //text-decoration: underline;
            background-color: white;
            color: black;
            box-shadow: var(--form-box-shadow);

        }
    }
}
.tab-content{
    //background-color: var(--site-background);
   // display: flex;
   padding: var(--gutter) 0;
    > div{
        display: none;
        &.show {
            display: block;
        }
    }
    
}

.tab-panel-header{
    width: 100%;
    //max-width: var(--site-max-width);
    margin: 0 auto;
    //padding: 0 10px;
    box-sizing: border-box;
    h2{
        //color: white;
        margin: 0;
        padding: 0;
        .text-secondary{
            font-size: small;
        }
    }
}

.tab-panel-content{
    
    //background-color: var(--site-background);
    .tab-content-wrapper{
        display: flex;
        flex-flow: row wrap;
        //max-width: var(--site-max-width);
        margin: 0 auto;
        
        gap: 20px;
        box-sizing: border-box;
        .chart{
            flex: 1 1 40%;
            min-width: 300px;
            background-color: white;
            border-radius:  var(--border-radius);
            padding: 20px;
            h3{
                margin-top: 0;
            }
            > div{
                //width: 100%;
                height: 300px;
                @include for-phone-only{
                    height: 200px;
                }
            }
        }
        
        .group-sales-lists{
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            gap: 20px;
            .article-group-list{
                flex: 1 66%;
                .accordion-button{
                    display: flex;
                    flex-flow: row wrap;
                    gap: 20px;
                    text-align: left;
                    .description{
                        flex: 1 50%;
                        @include for-phone-only{
                            flex: 1 100%;
                        }
                        
                    }
                    .item-qty{
                        min-width: 10%;
                        text-align: right;
                    }
                    .value{
                        flex: 1 20%;
                    }
                }
                
            }
            .terminal-sales-list{
                flex: 1 30%;
            }
        }
        .bestselling-articles{
            .list-group-values{
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 20%;
                text-align: right;
                //background-color: red;
            }
        }
    }
}

/*.stats-summary-item {
    container-name: poopers;
    container-type: normal;
    .statistic-value {
        font-size: 1.8rem;
    }
}

@container poopers (max-width: 300px) {
    .statistic-value {
        font-size: 0.1rem;
    }
}*/