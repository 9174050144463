.delivery-spots{
    display: flex;
    flex-direction: column;
    form{
        max-width: 1000px;
    }
    .line-numbered-text-text{
        max-height: 200px;
        resize: none;
    }
    > .btn-confirm-container {
        align-self: flex-end;
        .btn-delete{
            font-size: var(--btn-font-size);
            &:before{
                margin-right: 1rem;
            }
        }
    }
    .edit-delivery-spots{
        text-align: left;
        .form-info{
            white-space: pre-wrap;
        }
        .input-group{
            padding-top: 16px;
        }
    }
    .controls{
        .btn-group{
            justify-content: flex-end;
        }
    }
}
.delivery-spot-select{
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 10px;
}

.delivery-spot-result {
    li.disabled {
        background: rgb(249, 249, 249);
        & > span{
            opacity: 0.5;
        }
    }
    @include for-phone-only{
        .search-result > li {
            > *{
                &:first-child{
                    grid-column: auto;
                }
            }
        }
    }
}
