
/** CSS Variables **/
:root {
    --font-family-main: 'Montserrat',arial, sans-serif;
    --font-family-header: var(--font-family-main);
    --font-family-item-header: var(--font-family-main);
    --letter-spacing: 0;
    --site-max-width: 1400px;
    --navbar-padding: 10px;
    /** COLORS */
    /** primary **/
    --color-primary: #1F2130;
    --color-primary-contrast: white;
    --color-primary-shade: #db8300;
    --color-primary-body: #f5f5f5;
    --color-secondary: #55BA59;;
    --color-secondary-contrast: white;
    --chart-bar-color: #55BA59;
    --chart-line-color: #1F2130;
    /** secondary **/
    --color-secondary-body: #ffffff;
    /** success **/
    --color-success: #55BA59;
    --color-success-contrast: #ffffff;
    /** danger **/
    --color-danger: #B83E6A;
    --color-danger-contrast: #ffffff;
    /** text */
    --link-color: #000;
    --tab-content: #1d2741;
    --font-color: black;
    --font-color-muted: #666666;
  
    --font-color-header: var(--font-color);

    --list-item-selected-color: #0059d1;

    //--background-color-navbar: var(--color-primary);
    --background-color-navbar: linear-gradient(90deg, rgba(16,37,60,1) 96%, rgba(0,0,0,1) 100%);

    --form-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    --gutter: clamp(10px, 2vw, 25px);

    @include for-phone-only{
      --gutter: 4vw;
    }
    /* Font size */
    // --font-size: 50px;
  
    --font-size-h1: 2rem;
    --font-size-h2: 1.5rem;
    --font-size-h3: 1.2rem;
    --font-size-h4: 1.1rem;
    --font-size-text: #{size(16)};
    --font-size-btn-link: #{size(18)};
  
    /*@media screen and (min-width: $width-md) {
      --font-size-h1: #{size(32)};
      --font-size-h2: #{size(28)};
      --font-size-h3: #{size(22)};
      --font-size-h4: #{size(20)};
    }
  
    @media screen and (min-width: $width-lg) {
      --font-size-h1: #{size(36)};
      --font-size-h2: #{size(30)};
      --font-size-h3: #{size(24)};
      --font-size-h4: #{size(20)};
    }*/
    /* line height */

    --glow-color: green;


    --line-height: 1.6;
    --line-height-h1: 1.2;
    --line-height-h2: 1.2;
    --line-height-h3: 1.13;
    --line-height-h4: 1;
    
  
    --margin-p: #{size(10)};
    --margin-h1: #{size(16)};
    --margin-h2: #{size(16)};
    --margin-h3: 10px;
    --margin-h4: #{size(16)};
    --margin-btn-group: #{size(16)} 0;
    --content-padding: 0;
    --page-padding: #{size(10)};
    --padding-shop-list: 0 #{size(-10)};
    --margin-bottom-shop-list: #{size(25)};
    --list-page-header-padding: #{size(20)};
  

    /** COMPONeNTS **/
    /** buttons **/
    --color-primary-button-bg: var(--color-primary);
    --color-primary-button-text: var(--color-primary-contrast);
    --color-primary-button-text-hover: var(--color-primary-contrast);
    --color-primary-button-border: var(--color-primary);
    --color-primary-button-bg-hover: var(--color-primary-shade);
    --color-primary-button-border-hover: var(--color-primary-shade);
    --color-secondary-button-bg: #fff;
    --color-secondary-button-text: var(--color-primary);
    --color-secondary-button-text-hover: var(--color-primary-contrast);
    --color-secondary-button-bg-hover: var(--color-primary-shade);
    --color-secondary-button-border: var(--color-primary);
    --color-secondary-button-border-hover: var(--color-primary-shade);
    --btn-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.35);
    --btn-border: #{size(3)} solid;
    --btn-border-radius: #{size(66)};
    --btn-height: #{size(45)};
    --btn-padding: #{size(6)} #{size(35)};
    --btn-icon-margin: #{size(12)};
    --btn-font-size: 0.85rem;
    --btn-small-border-radius: #{size(56)};
    --btn-small-height: #{size(45)};
    --btn-small-font-size: #{size(14)};
    --btn-small-padding: 0 #{size(25)};
    --btn-text-transform: uppercase;
    /* Item */
    --border-radius: 10px;
  
    --site-background: #F2F3F5;
    --list-item-width: #{size(250)};
    --list-item-max-width: 1fr;
    --list-item-width-phone: #{size(160)};
    --list-item-max-width-phone: var(--list-item-max-width);
    --list-item-category-width: var(--list-item-width);
    --list-item-category-max-width: var(--list-item-max-width);
    --list-item-articles-width: var(--list-item-width);
    --list-item-articles-max-width: var(--list-item-max-width);
    --list-item-suggested-article-width: #{size(200)};
    --list-item-suggested-article-max-width: var( --list-item-max-width);
    /* shop-selector */
    --shop-selector-max-width: #{size(992)};
    /* forms */
    --input-border-color: #707070;
    /* Modal */
    --modal-backdrop-background-color: rgba(0, 0, 0, 0.16);
    --modal-background-color: #fff;
    --modal-header-background: var(--color-primary);
    --modal-header-color: var(--color-primary-contrast);
    /* Dropdown */
    --dropdown-font-color-hover: var(--font-color);
    /* Navbar */
    --navbar-height: #{size(60)};

  }
  