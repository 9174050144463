.form-footer{
    display: flex;
    //justify-content: space-between;
    gap: 20px;
    align-items: center;
    min-height: 50px;
}

.checkbox-wrapper{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 5px;
    margin: 5px 0;
    &:has(+ .form-info){
        margin-bottom: 0;
    }
    input[type="checkbox"] {
        visibility: hidden;
        display: none;
    }
    label{
        font-weight: bold;
        font-size: 0.85rem;
        text-transform: uppercase;
        
    }
    .toggle {
        position: relative;
        display: block;
        width: 42px;
        height: 24px;
        padding-right: 5px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transform: translate3d(0, 0, 0);
        &:before {
            content: "";
            position: relative;
            top: 1px;
            left: 1px;
            width: 40px;
            height: 22px;
            display: block;
            background: #c8ccd4;
            border-radius: 12px;
            transition: background 0.2s ease;
        }
        .toggler {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            display: block;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 2px 6px rgba(154,153,153,0.75);
            transition: all 0.2s ease;

            svg {
                margin: 7px;
                fill: none;
            
                path {
                    stroke: #c8ccd4;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-dasharray: 24;
                    stroke-dashoffset: 0;
                    transition: all 0.5s linear;
                }
            }
        }
    }
    input[type="checkbox"]:checked + .toggle:before {
        background: #52d66b;
    }
    input[type="checkbox"]:checked + .toggle .toggler {
        transform: translateX(18px);
    }
    input[type="checkbox"]:checked + .toggle .toggler path {
        stroke: #52d66b;
        stroke-dasharray: 25;
        stroke-dashoffset: 25;
    }
    &:has(input[disabled]){
        opacity: 0.5;
    }
}
.input-group{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 5px 20px;
    margin: 0px 0;
    position: relative;
    &:has(input:hover), &:has(.fancy-picker-select:hover){
        > label, > .label {
            &::before{
                width: 30px;  
            }
            
        }
    }
    .form-info{
        flex: 100%;
        margin-top: 0px;
        font-size: 0.85rem;
    }
    label, .label{
        position: relative;
        font-weight: bold;
        font-size: 0.85rem;
        text-transform: uppercase;
        width: 100%;
        margin-bottom: 3px;

    }
    > label, > .label{
        &:before{
            content: "";
            display: block;
            width: 0px;
            height: 2px;
            background-color: var(--color-primary);
            position: absolute;
            bottom: -3px;
            transition: width 300ms ease-in-out;
        }
    }
    
    input, .form-control, .checkbox-wrapper{
        flex: 1;
    }
    .checkbox-wrapper{
        //justify-content: end;
        label{
            min-width: auto;
            width: auto;

        }
    }
    select {
        /* reset */
    
        margin: 0;      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
    
        /* styling */
        background-color: white;
        border: thin solid rgb(197, 197, 197);
        border-radius: 4px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;
    
        background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    
    
        &:focus {
            border-color: var(--color-primary);
            outline: 0;
            box-shadow: none;
        }
        &:active{
            box-shadow: none;
        }

       
    }
}
.form-group {
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    margin-bottom: 10px;
}
.select-group{
    position: relative;

    select{
        //style the clearbutton if there is one

        &:has(+ .btn-clear) {
        padding-right: 4.5em;

        &:focus + .btn-clear{
            
            &:before{
                color: hsl(0, 0%, 40%); 
            } 
        }
    }
    }
    .btn-clear {
        position: absolute;
        right: 2.5rem;
        font-size: 0;
        border: 0;
        padding: 10px;
        @include icon(cancel, before, 0){
            font-size: 20px;
            color: hsl(0, 0%, 80%);
                
        }
        &:hover:before{
            color: hsl(0, 0%, 70%); 
        }
    }
}
.filter, .shop-info, .toolbar, .fancy-picker-dropdown{
    .input-group{
        label, .label{
            min-width: auto;
        }
    }
}

.form-select, input{
    padding: 0.5rem;
    border-radius: 4px;
    line-height: 1.5em;
    box-sizing: border-box;
    //border: none;
    //font-size: 0.85rem;
    //box-shadow: var(--form-box-shadow);
    border: thin solid rgb(197, 197, 197);
    transition: border-color 0.6s;
    background-color: white;
    &:hover{
      border-color: var(--color-primary);
    }

}
.form-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
}
.btn{
    padding: 5px 10px;
    border-radius: var(--border-radius); 
}

.select { //react select
    min-width: 300px;
}

.fancy-picker {
    
    display: flex;
    width: 100%;
    
    &.editable .fancy-picker-select{
        box-shadow: var(--form-box-shadow);
        background-color: white;
        .selected-item{
            padding: 5px 10px;
        }
      }
    .fancy-picker-select{
      border-radius: var(--border-radius);
      min-width: 300px;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      position: relative;
      .selected-item{
        flex: 1 100%;
      }
      .btn-dropdown{
        padding: 10px;
        border-left: 1px solid #A4A4A4;
        border-radius: 0;
        
      }
      .btn-clear{
        padding: 0;
        border: none;
      }
    }
    //.selected-item
    .fancy-picker-dropdown {
      position:absolute;
      top:40px;
      right: -10%;
      background: white;
      border:solid 1px #E4E4E4;
      border-radius: 5px;
      z-index: 1000;
      width: 120%;
      max-width: 400px;
      .input-group{
        padding: 10px;
        gap: 10px;
        label{
            width: auto;
        }
      }
      ul {
        list-style: none;
        max-height: 400px;
        overflow-y: scroll;
        //padding: 0;
        @include selectebleListStyle();
        //li {
          //font-size: 0.85rem;
        //}
      }
      .option-value{
        &:before{
            content: "(";
        }
        &:after{
            content: ")";
        }
      }
    }
    
    &.open + .dialog-backdrop{
        display: block;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 999;
        background-color: transparent
    }
}
.dialog-backdrop{
    display: none;
}
// fieldset reset

legend {
    display: table;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;

    + * {
        clear: both;
    }
}

fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}
////////////////////////

/*form{
    fieldset{
        max-width: 1000px;
        background-color: white;
        padding: 20px;
        border: 1px solid #e0e0e0;
        border-radius: var(--border-radius);
        margin-bottom: 10px;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    legend{
        font-size: var(--font-size-h2);
        font-weight: bold;
    }
}*/