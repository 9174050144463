.shops {
  .shop{
    display: relative;
  }
  .overrides {
    list-style: none;
    >li{
      margin-bottom: var(--gutter);
    }
  }
  .shop-header{
    display: flex;
    gap: 20px;
    align-items: center;
    .btn-confirm-container {
      margin-left: auto;
    }
  }
  .shop-status{
    display: flex;
    gap: 20px;
    align-items: center;
    > span {
      display: flex;
      gap: 5px;
      align-items: center;
      text-transform: capitalize;
    }
    
    
  }
  .image-upload{
    .drop-area{
      aspect-ratio: 16/9;
    }
  }
  .shop-details{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    gap: var(--gutter);
    margin-bottom: var(--gutter);
    margin-top: 30px;
  }
  .shop-opening-hours, .shop-info{
    background-color: white;
    border-radius: var(--border-radius);
  }
  .shop-opening-hours {
    flex: 4 1.5 50%;

    .hours-header{
      display: flex;
      gap: 20px;
      padding: 20px;
      margin: 0;
      cursor: pointer; 
    }
    .active-now { //TODO: how do we want to show this.
      color: green;
      font-weight: bold;
      margin-left: 10px;
    }
    .toolbar {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      padding: 0 20px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      .input-group {
        [name="name"] {
          width: 300px;
        }
      }
      .toolbar .btn-group {
          margin-left: auto;
      }
      .btn-delete{
        border-radius: 50%;
        padding: 15px;
       // font-size: 35px;
        box-shadow: var(--form-box-shadow);
        background-color: white;

        margin-left: auto;
        &::before{
          margin: 0;
        }
      }
    }
    .opening-hours { //ol
      list-style: none;
      padding: 0;
      margin: 0;

      .day, .opening-hours-prompt {
        padding: 20px;
        border-bottom: 1px solid #d6d6d6;
        &:first-child {
          border-top: 1px solid #d6d6d6;
        }
        display: flex;
        justify-content: left;
        line-height: 1.5;
        align-items: center;
        .set-time{
          opacity: 1;
          transition: opacity 0.3s;
        }
        > * {
          margin-left: 10px;
        }
        &:has(.closed){
          .set-time{
            opacity: 0.5;
            pointer-events: none;
          }
          .closed{
            opacity: 1;
          }
          
        }
        .closed{
          font-size: 0.85rem;
          font-weight: bold;
          opacity: 0;
          animation: fadeInAnimation ease 0.3s;
        }
      }
      @include for-tablet-portrait-up{
        .checkbox-wrapper{
          min-width: 200px;
          
        }
      }
      
    }
    .form-footer{
      padding: 20px;
    }

    &.collapsible.collapsed {
      .opening-hours-details {
        display: none;
      }
    }

  }
  .shop-status-container{
    flex: 1 0 320px;
    .spots-btn{
      margin-top: 10px;
    }
  }
  .shop-info{
    padding: 20px;
    
    position: relative;
    h4 {
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .input-group{
      margin: 10px 0;
      justify-content: space-between;
    }
    .dummy-image {
      width: 100%;
    }
    .shop-image{
      aspect-ratio: 16/9;
      background: rgb(243, 243, 243);
      border: thin solid rgb(203, 203, 203);
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        max-width: 100%;
        max-height: 100%;
        
      }
    }
    .shop-status {
      position: absolute;
      top: -20px;
      right: -10px;
      background-color: white;
      box-shadow: var(--form-box-shadow);
      padding: 5px 10px;
      border-radius: var(--border-radius);
      font-weight: bold;
      font-size: .85rem;
    }
    .shop-location {
      .label {
        display: block;
      }
    }
  }
  .shop-list {
    margin: var(--gutter);
    list-style: none;
    margin: 0;
    background-color: white;
    padding: 20px;
    border-radius: var(--border-radius);
  }
  .btn-large-add {
    margin: auto;
  }
  .shop-not-pnp-ready{
    margin: var(--gutter) 0;
  }
  .spots-btn{
    display: flex;
    margin-left: auto;
    background: url(../images/pickupspots-left.png) no-repeat bottom left, #264c57;
    background-size: 50px;
    height: 50px;
    padding-left: 70px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.46) 0px 1px 4px;
    border: none;
    justify-content: flex-start;
  }
}

.pnp-status{
  @include icon('globe', 'before', 5px){
    color: red;
    font-size: 25px;
    margin-top: -5px;
  }
  &.status-online{
    &:before {
      color: green;
    }
  }
}
.shop-hours{
  @include icon('clock', 'before', 5px){
    color: red;
    font-size: 25px;
  }
  &.hours-open{
    &:before {
      color: green;
    }
  }
}