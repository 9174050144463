$nav-padding: 10px 15px;

@include for-tablet-portrait-up{
    aside {
        background: var(--background-color-navbar);
        min-height: 100vh;
        max-width: 320px;
    }
    .navbar{
        height: 100vh;
    }
}

aside{
    position: relative;
}
.navbar{
    display: flex;
    flex-direction: column;
    padding: var(--gutter);
    box-sizing: border-box;
    a{
        text-decoration: none;
    }
    .navbar-brand{
        display: flex;
        align-items: center;
        z-index: 100;
        .logo{
            display: block;
            width: 100%;
            margin-bottom: var(--gutter);
        }
        .icon {
            display: none;
        }
    }
    .locale-selector{
        padding-top: 20px;
        justify-self: flex-end;
        text-align: center;
        z-index: 1;
        position: relative;
        width: 100%;
        &:before {
            content: "";
            border-top: 1px solid white;
            display: block;
            padding-bottom: 10px;
        }
    }
    .navbar-nav{
        list-style: none;
        //margin-left: -15px; //The padding of the link
        .navbar-nav {
            margin-left: 15px;
        }
        .nav-link{
            display: block;
            padding: 10px 15px;
            font-weight: bold;
            border-radius: var(--border-radius);
            cursor: pointer;
        }
        .nav-item{
            .navbar-nav{
                display: none;
            }
            &.active > .nav-link{
                background-color: white;
                color: var(--color-primary);
            }
            &.active, &.child-active{
                > .navbar-nav{
                    display: block;
                }
            } 
        }
        
    }
    .nav-link{
        color: white;
    }
    .btn-logout{
        color: white;
        padding: 10px 15px;
        margin-top: 20px;
        font-size: 1rem;
    }
    
}
.version{
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.7rem;
    color: white;
    opacity: 0.8;

}

@include for-phone-only{
    .navbar{
        position: relative;
        //height: 100px;
        .nav-container{
            position: absolute;
            z-index: 1;
            width:90vw;
            height: 100dvh;
            left: -110vw;
            top:0;
            transition: left 0.5s ease-in-out;
            padding: 80px 20px 20px;
        }
        .icon{
            filter: brightness(1) invert(0);
            transition: filter 600ms;
        }
        &.show{
            .icon {
                filter: brightness(0) invert(1);
            }
            .nav-container{
                left: 0; 
                
            }
        }
    }
    .navbar-header{
      display: grid;
      grid-template-columns: 40% 20% 40%;
      grid-template-areas: 'burger logo .';
      .navbar-brand{
        grid-area: logo;
        align-self: center;
        justify-self: center;
        .logo{
            display: none;
        }
        .icon {
            display: block;
        }
        img{
            width: 35px;
            height: 35px;
        }
      }     
    }
    .nav-container{
        background: var(--background-color-navbar);
    }
}
.navbar-toggler{
    grid-area: burger;
    justify-items: start;
    display: none;
    @include for-phone-only{
        display: flex;
        z-index: 100;
    }
    background-color: transparent;
    border: none;
    padding: 0;
    svg{ 
        
      .line {
        fill: none;
        stroke: var(--color-primary);
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke 600ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }
      .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
      }
      .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }
    }
    &.show svg{ 
      .line{
        stroke: white;
      }
      .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
      .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }
      .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
    }
}

.filter-container{
    padding-bottom: 0;
    //text-transform: uppercase;
    h1{
        margin-top: 0;
    }
    .filters{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        gap: var(--gutter);
        margin-bottom: var(--gutter);
        .filter-label{
            width: 100%;
            text-transform: uppercase;
        }
    }
    label, .input-group-text{
        font-weight: bold;
        font-size: 0.85rem;
    }
    .row{
        align-items: center;
    }
    .period {
        flex: 1 100%;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        gap: 10px 20px;
    }
    .profitcenter-area-picker{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 10px;
    }
}
.form-check{
    display: flex;
    align-items: center;
    input{
        position: relative;
        right: -100000px;
        &:checked ~ label:before{
            background-color: var(--color-secondary);
        }
    }
    label{
        display: flex;
        align-items: center;
        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-color: white;
            
        }
    }
}

