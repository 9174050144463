.generate-qr .generator-container {
    display: grid;
    grid-template-columns: auto clamp(320px, 40%, 400px);
    //justify-content: space-between;
    @include for-phone-only {
        grid-template-columns: 1fr;
    }
    gap: var(--gutter);
    align-items: start;
  
  .generator-form {
    
    textarea {
        width: 100%;
        height: 100px;
    }
    .colors-section {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
    }
  }
  
  .qr-container {
    border: solid 1px #e0e0e0;
    border-radius: var(--border-radius);
    overflow: hidden;
    .qr-code{
      background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
      background-size: 20px 20px;
      background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
      max-width: 100%;
    }
    &.download {
      background-image: initial;
    }
    .btn-download{
      background: white;
    }
    
  }
}

.color-picker {
    .color-preview-container {
      background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
      background-size: 20px 20px;
      background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  
      width: 26px;
      height: 26px;
      border: solid 1px black;
      
      .color-preview {
        margin:2px;
        border: solid 1px black;
        width: 20px;
        height: 20px;
      }
    }
    .popover {
      position: absolute;
      z-index: 2;
    }
    .cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }

  .line-numbered-text {
    width: 100vw;
    max-width: 400px;
    display: flex;
    border: 1px solid rgb(203 213 225);
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: 32rem;
    font: 1rem/1.5 monospace;
    .line-numbered-text-text {
      border: none;
      outline: none;
      //padding: 0.5rem;
      width: 100%;
      line-height: 25px;
      padding-left: 5px;
    }
    .line-numbered-text-line-numbers {
      border-right: 1px solid rgb(203 213 225);
      //padding: 0.5rem;
    
      text-align: right;
      overflow: hidden;
      //line-height: 18px !important;
      div{
        padding: 0 4px;
      }
    }
  }