@font-face {
  font-family: 'dashboard-icons';
  src: url('../font/dashboard-icons.eot?57361341');
  src: url('../font/dashboard-icons.eot?57361341#iefix') format('embedded-opentype'),
       url('../font/dashboard-icons.woff2?57361341') format('woff2'),
       url('../font/dashboard-icons.woff?57361341') format('woff'),
       url('../font/dashboard-icons.ttf?57361341') format('truetype'),
       url('../font/dashboard-icons.svg?57361341#dashboard-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* the icon references are in the font.scss file */
