@import 'font/icons/font/dashboard-icons.scss';
// Default project uses regular and bold as font weights. 

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("font/Montserrat-Light.woff2") format("woff2"), url("font/Montserrat-Light.woff") format("woff");
  font-display: swap;
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("font/Montserrat-LightItalic.woff2") format("woff2"), url("font/Montserrat-LightItalic.woff") format("woff");
  font-display: swap;
}
/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("font/Montserrat-Regular.woff2") format("woff2"), url("font/Montserrat-Regular.woff") format("woff");
  font-display: swap;
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("font/Montserrat-Italic.woff2") format("woff2"), url("font/Montserrat-Italic.woff") format("woff");
  font-display: swap;
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("font/Montserrat-Bold.woff2") format("woff2"), url("font/Montserrat-Bold.woff") format("woff");
  font-display: swap;
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("font/Montserrat-BoldItalic.woff2") format("woff2"), url("font/Montserrat-BoldItalic.woff") format("woff");
  font-display: swap;
}