@import 'campaign';
@import 'stickers';
@import 'shops';
@import 'terms';
@import 'settings';
@import 'payment-providers';
@import 'menu';
@import 'qr-generator';
@import 'customer-details';
@import 'location-picker';
@import 'order-details';
@import 'delivery-spots';
@import 'customers';


.pick-n-pay {
    .result-container{
        margin: var(--gutter) 0;
    }
    
}
.widgets {
    display: grid;
    
    gap: var(--gutter);
    .widget {
        h2{
            margin: 0;
            padding: 20px;
        }
        background-color: white;
        border-radius: var(--border-radius);
        //padding: 20px;
        box-shadow: 0px 0px 10px 0px #E4E4E4;
        max-width: 500px;
        ul {
            list-style: none;
            padding: 0;
            display: table;
            width: 100%;
            //border-spacing: 5px 20px;
            li.no-shops {
                display:block;
                padding: 20px;
                text-align: center;
                background-color: initial;
            }
            li{
                display: table-row;
                
                .label{
                font-weight: bold;
                }
                span {
                    display: table-cell;
                    padding: 5px 10px;
                    vertical-align: middle;
                }
                .shop-name{
                    padding-left: 20px;
                }
                &:nth-child(odd){
                    background-color: #f9f9f9;
                }
            }
     }
  }
}
.shop-status-widget{
    .shop-status-table{
        max-height: 480px;
        overflow-y: auto;
    }
    .pnp-status, .shop-hours{
        white-space: nowrap;
    }
}

.post-install-tasks{
    min-height: 250px;
    font-weight: bold;
    padding-bottom: 20px;
    ol {
        margin: 20px;
        padding: 0 20px;
    }

    li{
        margin: 10px 0;
        &.task-done a{
            @include icon(check, after, 5px){
                color: green;
                font-size: 25px;
                text-decoration: none;
            }
        }
    }

    .ready-for-live{
        font-size: 25px;
        font-weight: bold;
        padding: 0 20px;
        @include icon(rocket){
            font-size: 45px;
        }
    }
    background: url(../images/no-rocket.svg) no-repeat bottom right, #39b0a9;
    background-size: 40%;
    &.all-tasks-done{
        background: url(../images/rocket.svg) no-repeat bottom right, #39b0a9;
        background-size: 40%;
        color: white;
        a {
            color: white;
        }
    }
}
.search-filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 var(--gutter);
    max-width: 800px;
}
.container{
    overflow: hidden;
    position: relative;
    &:has(.confirm-open), &:has(.fancy-dropdown.open){
        overflow: visible;
    }
}
.filter-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80vh;
    max-width: 500px;
    z-index: 1;
    background-color: white;
    padding: var(--gutter);    
    transition: right 400ms ease-out;
    box-shadow: 0px 0px 10px 0px #E4E4E4;
    .filter-panel-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--gutter);
        h2{
            margin: 0;
        }
    }
    .filter-panel-footer{
        display: flex;
        justify-content: space-between;
        gap: var(--gutter);
        border-top: 1px solid #E9E9E9;
        margin-top: var(--gutter);
        padding-top: 10px;
    }
    &.hidden{
        right: -700px;
    }
}

