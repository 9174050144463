
.campaigns {
  
}

  
.campaign-list {
  list-style: none;
  margin: 0;
  background-color: white;
  //padding: 20px;
  border-radius: var(--border-radius);
  
  .campaign-list-item {
    .campaign-header{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      //cursor: pointer;
      user-select: none;
      .btn-expander{
        padding: 20px 0 20px 20px;
        cursor: pointer;
      }
      h3 {
        display: inline-block;
        margin: 0;
        flex: 1;
        padding: 20px;
        cursor: pointer;
      }
      .locale-selector {
        pointer-events: none;
        button{
          &:active{
            border: none;
          }
        }
      }
      &.expanded{
        .locale-selector {
          pointer-events: all;
          button{
            &.active { 
              border: 2px solid var(--color-primary);
            }
          }
        }
      }
      .campaign-code {
        &:before {
          content: ' (';
        }
        &:after {
          content: ')';
        }
      }
    } 

    .system-activated {
      margin-left: 20px;
      + .system-activated{
        margin-right: 20px;
      }
      display: flex;
      align-items: center;
      font-size: 0.85rem;
      font-weight: bold;
      @include icon('cancel', 'before', 8px){
        color: var(--color-danger);
        font-size: 1.5rem
      };
      &.enabled{
        @include icon('check', 'before', 8px){
          color: var(--color-success)
        }
      }
    }
    .campaign-image {
      border-radius: var(--border-radius);
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 4 / 3;
    }
    &:not(:last-child){
      border-bottom: #d6d6d6 1px solid;
    }
    
  }
  .campaign-details {
    display:flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 20px;
    padding:20px;
    position: relative;


    .campaign-image{
      aspect-ratio: 4 / 3;
      .selected-image{
        max-width: 100%;
        max-height: 100%;
      }
    }
    .campaign-column{
      flex: 1;
      hyphens: auto;
      word-break: break-word;
      label {
        font-weight: 0.85rem;
        font-weight: bold;
      }
      ul{
        list-style: none;
        max-height: 100px;
        overflow: auto;
        margin-bottom: 10px;
        li{
          font-size: 0.85rem;
          //margin-top: 3px;
          display: flex;
          flex-flow: row wrap;
          gap: 5px;
        }
        .condition-type, .item-type {
          font-weight: bold;
          &::after{
            content: ":";
          }
        }
      }
      .input-group{
        margin-bottom: 20px;
      }
      .editor-container{
        margin: 0;
      }
      &.actions{
        flex: none;
      }
      .upload-info{
        display: block;
        font-size: 0.85rem;
        margin-top: 5px;
      }
    }
    .campaign-footer{
      flex: 1 100%;
      display: flex;
      justify-content: flex-end;
    }
    
  }
  .create-campaign{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.2);
    pointer-events: none;
    .btn{
      pointer-events: all;
    }
  }
} 

