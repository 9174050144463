.leaflet-container {

    height:500px;
    max-width: 600px;
    width: 80vw;
    //min-width: 320px;

    .leaflet-control-container .leaflet-bar{
        border: none;
        width: 250px;
    }
    .leaflet-bar-part.leaflet-bar-part-single{
        display: none;
    }
    .geosearch{
        form{
            position: relative;
            .reset{
                position: absolute;
                right: 5px;
                top: 5px;
                cursor: pointer;
            }
        }
        input{
            width: 100%;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #e0e0e0;
            padding-right: 30px;
        }
    }
    .results.active{
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 480px;

        max-width: 580px;
        width: 70vw;
        > div{
            padding: 0 5px;
            cursor: pointer;
            border-bottom: 1px solid #e1e1e1;
            &:hover{
                background-color: #f0f0f0;
            }   
        }
    }
  }