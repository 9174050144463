.modal-backdrop {
    background-color: rgba(17, 12, 46, 0.35);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;
    &.fade{
        opacity: 0;
    }
    &.show{
        opacity: 1;
    }
}
.modal{
   position: fixed;
   z-index: 1;
   top:0;
   bottom: 0;
   left: 0;
   right: 0;

   .list{
    .list-item {
        &:hover{
            background-color: rgb(237, 237, 237);
            cursor: pointer;
        }
    }
   }
}
.modal-dialog {
    background-color: white;
    border-radius: var(--border-radius);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    
}
.modal-content{
    height: 80vh;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
}
.modal-header{
    display: flex;
    justify-content: space-between;
    
    padding: 10px;
}
.modal-body{
   // height: 200px;
   flex: 1 1 auto;
   overflow-x: none;
   overflow-y: auto;
    .list-group-item{
        cursor: pointer;
    }
}
.modal-footer {
    padding: 10px;
    display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
}
.fade {
    transition: opacity .15s linear;
    &:not(.show) {
        opacity: 0;
    }
}

.confirm-dialog{
    overflow: hidden;
    .modal-header{
        background-color: var(--background-color-navbar);
        color: white;
    }
    .modal-content{
        //padding: 20px;
        height: auto;
    }
    .modal-body{
        padding: 0 10px;
    }
}

//new dialog

dialog{  
    &::backdrop{
        background: rgba($color: #000000, $alpha: 0.3);
    }
    border: none;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12);;
    max-width: 600px;
    border-radius: var(--border-radius);
    h1{
        margin: 0 0 1rem;
        font-size: 1.2rem;
    }
    .dialog-footer{
        justify-content: flex-end;
        padding-top: 10px;
        .btn{
            margin: 0;
        }
    }
}