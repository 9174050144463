.menu-page, .menu-editor-page{
  h1{
    margin-top: 0px;
    margin-bottom: 0;
  }
}

.menu-page{
  .btn-large-add{
    justify-content: flex-start;
    padding: 20px 0px;
  }
}

.menu-editor-page{
  display: flex;
  flex-flow: column;
  gap: var(--gutter);
  top: var(--gutter);
  bottom: 0;
  position: fixed;
}

.menu-name{
  
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid lightgray;
  input{
    max-width: 400px;
  }
}

.dragging-source {
  opacity: 0.9;
  background-color: white;
}


.menu-editor{
  display: flex;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  overflow: hidden;
  height: 100%;
  width: 100%;
  flex-flow: row;
  gap: var(--gutter);
  .menu-header{
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .article-search {
    //border: 1px solid green;
    height: 100%;
    overflow: hidden;
    //flex: 1 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .filters{
      display: grid;
      grid-template-columns: auto 1fr;
    }
    
    li.article{
      >*{
        padding: 5px;
      }
      &.selected {
        //color: blue;
        >*{
          background: var(--list-item-selected-color);
          color: white;
        }
        &.used h1::before{
          color: white;
          border-color: white;
        }
        
      }
      &.used {
        h1 {
          @include icon(check, before, 5px){
            color: green;
            border: 2px solid green;
            padding: 3px;
            border-radius: 50%;
          }
        }
      }
      position: relative;
      &:hover .article-id{
        opacity: 0.9;
      }
      .article-id{
        font-weight: normal;
        font-size: small;
        position: absolute;
        background-color: white;
        padding: 10px;
        box-shadow: var(--form-box-shadow);
        border-radius: 5px;
        right: 10px;

        opacity: 0;
        transition: opacity 0.3s 0.3s;
        &::before{
          content: " (";
        }
        &::after{
          content: ")";
        }
      }
    }
  }
  .result-container{
    margin: 0 !important;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    box-sizing: content-box; 

    background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: var(--border-radius);
      //padding: 10px 0;
      
    .scroll-observer{
      background-color: transparent;
      height: 1px;
      width: 1px;
    }
    >li > .btn-group{
      flex: none;
    }
  }
  .result {
    
    
    .article{
      display: grid;
      grid-template-columns:60px 1fr auto;
      grid-template-areas: 'img header department';
      border-bottom: 1px solid #e0e0e0;
      img{
        grid-area: img;
        background-color: white;
      }
      h1{
        grid-area: header;
        font-size: var(--font-size-text);
        background-color: white;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: 5px;
      }
      .department{
        grid-area: department;
        display: flex;
        align-items: center;
        background-color: white;
      }
    }
  }
  img {
    max-width: 50px;
    pointer-events: none;
  }
  .image-error {
    content: url(../images/no-image.svg);
    width: 30px;
    height: 30px;
    margin: auto;
  }
}
.shift-info{
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: space-between;
  font-size: small;
  p{
    margin: 0 0 5px;
  }
}
.article{
  &:has(.placeholder){
    .container{
      border-color: #1a4283;
    }
  }
}
.menu-builder{
  height: 100%;
  //overflow: hidden;
  width: 100%;
  //flex: 1 50%;
  display: flex;
  flex-direction: column;
  ul{
    list-style: none;
    
    ul{
      padding-left: 30px;
      padding-top:5px;
      padding-bottom: 5px;
    } 
  }
  .menu-tools{
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    justify-content: space-between;
  }
  .menu-container{
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: var(--border-radius);
      padding: 10px;
      max-height: 100%;
      overflow-y: auto;
      .btn-group{
        justify-content: flex-end;
      }
      &:has(.confirm-open){
        overflow: visible;
      }
      &:has(.dragging-source){
        li[draggable="true"]{
          opacity: 0.7;
        }
      }
  }
  .menu-item{
      margin-bottom: 2px;
      display: grid;
      gap: 10px;
      grid-template-columns: 40px 1fr auto auto;
      align-items: center;
      border: 1px solid #e2e2e2;
      border-radius: var(--border-radius);
      background-color: #fcfcfc;
      width: 100%;
      > span{
          padding: 14px;
          display: flex;
          align-items: center;
          
          gap: 10px;
      }
      
      &.article .image-error {
        margin: inherit;
        padding-left: 10px
      }
      &.separator {
          margin: 15px 0 5px;
          border: none;
          background: none;
          border-bottom: black 1px solid;
          border-radius: 0;
          span{
            padding: 0;
            
          }
      }
      &.placeholder {
          //border-mi: 2px solid #1a4283;
          display: flex;
          align-items: center;
          height: 10px;
          animation: placeholderIn 0.3s;
          margin-left: -5px;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #1a4283;
          }
          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: #1a4283;
          }
      } 
      .btn-group{
        margin-left: auto;
      }
      &.container{
        > span{
          @include icon(right-open, before, 0){
            font-size: 25px;
            transition: transform 0.3s
          };
        }
        &:has(+ul){
          span:before{
            transform: rotate(90deg);
          }
        }
      }
      .tools{
        opacity: 0;
        transition: opacity .6s;
        padding-right: 10px;
      }
      &:hover, &:has(.confirm-open){
        .tools {
          opacity: 1;
        }
      }
      /*.btn-confirm-dialog {
        right: 0;
        bottom: 0;
      }*/
  }
  .empty-text{
    display: none;
  }
  .menu {
    min-height: 350px;
    list-style: none; 
    &:empty{
      //height: 250px;
      background: url(../images/dragdrop.svg) no-repeat center center;
      background-size: 200px;   
      
      + .empty-text{
        font-weight: bold;
        text-align: center;
        display: block;
      }
    }
    
  }
  .menu-footer{
    margin-top: 20px;
  }
}
.menu-editor-page .article{
  position: relative;
  //border: 2px solid transparent;
  .article-code{
    font-size: 0.85rem;
    color: gray;
    //margin-left: auto;
  }
  &.added{    
    animation: addedBG 5s;    
  }
  
  &.dragging{
    //background-color: rgba($color: white, $alpha: 0.5);
    border: none !important;
    .department{
      opacity: 0;
    }
  }
  &.multiple {
      &:before{
        content: attr(data-selected-count);
        background-color: rgba(255, 0, 0, 0.698);
        border-radius: 20px;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
}



@keyframes placeholderIn {
  0% {
    height: 0px;
  }
  100% {
    height: 10px;
  }
}
@keyframes addedBorder {
  0% {
    border-color: transparent;
  }
  10%{
    border-color: var(--color-primary);
  }
  20% {
    border-color: transparent;
  }
  30%{
    border-color: var(--color-primary);
  }
  40% {
    border-color: transparent;
  }
  50%{
    border-color: var(--color-primary);
  }
  90%{
    border-color: var(--color-primary);
  }
  100% {
    border-color: transparent;
    
  }
}
@keyframes addedBG {
  0% {
    background: transparent;
    
  }
  10%{
    background: var(--list-item-selected-color);
    color: white;
  }
  90%{
    background: var(--list-item-selected-color);
    color: white;
  }
  100% {
    background: transparent;
    
  }
}
@keyframes test2 {
  0% {
    height: 50px;
  }
  100% {
    height: 0px;
  }
}