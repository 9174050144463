.image-upload{
  position: relative;
  &.has-image .drop-area{
    //background: none;
    position: relative;
    .drop-info{
      opacity: 0;
    }
    &.drag-over{
      background: none;
      
    }
    img{
      transition: opacity 0.3s;
    }
    .btn-clear-image{
      position: absolute;
      top: calc(50% - 60px);
      right: calc(50% - 25px);
      background-color: white;
      box-shadow: var(--form-box-shadow);
      border-radius: 50%;
      width: 50px;
      height: 50px;
      border: none;
      font-size: 0;
      z-index: 3;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      @include icon(trash, before, 0){
        font-size: 30px;
      };
      cursor: pointer;
      &:hover{
        transition-duration: 0.1s;
        scale: 1.1;
      }
    }
    &:hover{
      img{
        opacity: 0.5;
      }
      .btn-clear-image{
        top: calc(50% - 25px);
        opacity: 1;
      }
    }
  }

  .drop-area {
    display: flex;
    flex-direction: column;
    background-color: #E4E4E4;
    border-radius: var(--border-radius);
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");        
    aspect-ratio: 1024 / 768;
    transition: all 0.5s;
    form{
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
    }
    img{
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      inset: 0;
      margin: auto;

      &.hidden{
        display: none;
      }
    }
    .drop-info{
      display: flex;
      flex-flow: column;
      gap: 10px;
      align-items: center;

      @include icon('image', 'before', 0px){
        font-size: 45px
      };
    }
    &.drag-over{
      background-color: rgb(0, 221, 254);
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='54' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    
  }
}
.dummy-image{
  width: 100%;
  height: 100%;
  background-color: #E4E4E4;
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  //aspect-ratio: 4 / 3;

  @include icon('image', before, 0){
    font-size: 4rem
  };
}