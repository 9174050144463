.login{

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
    backdrop-filter: blur(2px);
    .login-dialog{

        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background-color: white;
        border-radius: var(--border-radius);
        padding: 20px;
        width: 80vw;
        max-width: 350px;
    
        h3{
            padding: 0;
            margin: 0 0 10px 0;
        }
        .input-group{
            margin: 15px 0;
        }
        .e-mail-input, .password-input{
            padding: 5px 0;
            label, input{
                display: block;
                width: 100%;
            }
            
        }
        .reset-password{
            display: block;
            margin-top: 10px;
        }
    
    }
    &-footer{
        display: flex;
        justify-content: flex-end;
        gap: 20px;

    }
}
.external-login.winpos {
    bottom: var(--gutter);
    position: absolute;
    right: var(--gutter);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span{
        margin-bottom: 3px;
        font-size: 0.85rem;
    }
    .alert {
        max-width: 500px;
    }
    .ms-login-btn{
        background: url(./images/ms-symbollockup_signin_dark.svg) no-repeat;
        width: 215px;
        height: 41px;
        border: none;
    }
    
}