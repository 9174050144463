button{
    cursor: pointer;
}
.btn{
    //border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 7px 15px;
    font-weight: bold;
    border: 1px solid var(--color-primary);
    background: none;
    font-size: var(--btn-font-size);
    margin: 5px 0;
    text-decoration: none;
    &-close{
        //@include cross(20px, #000, 2px);  
        border: none;
        padding: 0;
        @include icon('cancel', 'before'){
            font-size: 20px;
        }
    }
    &-secondary{
        border: 1px solid lightgray;
    }
    &-primary {
        color: #fff;
        background-color: var(--color-primary);
        border: none;
    }
    &-danger{
        background-color: var(--color-danger);
        border: none;
        color: white;
    }
    &-edit{

        @include icon('pen', 'before'){
          font-size: 20px;
        }
        /*&.btn-primary{
            font-size: 1rem;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 20px;
            background-color: var(--color-primary)
        }*/
    }
    
    &-delete {
        @include icon('trash', 'before');
        /*font-size: 0;
        background: none;
        border: none;
        padding: 4px 15px;
        @include icon('trash', 'before', 0){
          font-size: 20px;
        }
        */
    }

    &-remove {
        @include icon('trash', 'before');
    }

    &-expander{
        background: none;
        border: none;
        @include icon('right-open', before){
            font-size: 20px;
            transition: transform 0.3s;
        }
        &.expanded{
            &:before{
                transform: rotate(90deg);
            }
            
        }
    }
    &-dropdown{
        background: none;
        border: none;
        @include icon('down-open', before, 0);
    }
    &-clear{
        background: none;
        //border: none;
        padding: 4px 15px;
        @include icon('cancel', before){
            font-size: 20px
        };
    }
    
    &-add-user{
        @include icon('user-add', 'before'){
            font-size: 20px;
        }
    }
    &-save{
       @extend .btn-primary;
    }
    &-create{
        @include icon('plus', 'before', 10px){
            font-size: 35px;
        }
    }
    &-copy{
        @include icon('docs', 'before');
    }
    &-add{
        @include icon('plus', 'before');
    }
    &-expand{
        @include icon('expand', 'before');
    }
    &-collapse{
        @include icon('collapse', 'before');
    }
}
.hover-btn {
    border: none;
    padding: 8px;
    margin: 0;
    position: relative;
    border-radius: 50%;
    transition: background-color 0.3s;
    .btn-text{
      position: fixed;
      background-color: var(--color-primary);
      color: var(--color-primary-contrast);
      padding: 5px 10px;
      box-shadow: var(--form-box-shadow);
      border-radius: var(--border-radius);
      margin-top: -55px;
      opacity: 0;
      z-index: 1000;
      white-space: nowrap;
      transition: opacity 0.5s 0.5s;
      pointer-events: none;
    }
    &:hover{
      .btn-text{
        opacity: 1;
        transition-delay: 0;
      }
      background-color: rgb(0, 0, 0, 0.05);
      &.btn-primary{
        background-color: var(--color-primary);
      }
    }
    &:before{
        font-size: 20px;
        margin: 0;
    } 
    &.hover-right{
        .btn-text{
            position: absolute;
            margin-top: 0;
            top: -25px;
            right: -10px;
        }
    }
    &.hover-left{
        .btn-text{
            position: absolute;
            margin-top: 0;
            top: -25px;
            left: -10px;
        }
    }
  }
.expanded{
    .btn-expander{
        &:before{
            transform: rotate(90deg);
        }
    }
}
.btn-group{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 5px;
}
.btn-outline-light{
    border: 2px solid white;
    background: transparent;
    color: white;
}
.add-user-btn{
    display: flex;
    align-items: center;
    gap: 5px;
    &::before {
        content: '+';
        font-size: 30px;
    }
    
}

.btn-picker{
    background-color: white;
    color: var(--font-color);
    font-weight: 500;
    //margin-left: 10px;
    border-radius: var(--border-radius);
    padding: 5px 20px;
    font-size: 0.75rem;
    text-wrap: nowrap;
    font-weight: bold;
    box-shadow: var(--form-box-shadow);
    cursor: pointer;
    &.selected{ // selected
        background-color: var(--color-primary);
        color: white;
    }
    border: 1px solid transparent;
    transition: border-color 0.6s;
    &:hover{
      border-color: var(--color-primary);
    }
}
.btn-add{
    background: none;
    border: none;
    display: flex;
    align-items: center;
    @include icon('plus', 'before', 0){
        font-size: 25px;
        //mix-blend-mode: overlay;
    }
}
.btn-large-add{
    display: flex;
    align-items: center;
    gap: 20px;
    background: none;
    border: none;
    @include icon('plus', 'before', 0){
        font-size: 25px;
        //mix-blend-mode: overlay;
        border-radius: 50%;
        padding: 15px;
        font-size: 35px;
        box-shadow: var(--form-box-shadow);
        background-color: white;
    }


}
.btn-confirm-container {
    display: inline-block;
    position: relative;
    
    .btn-confirm-dialog {
      min-width: 400px;
      position: absolute;
      background-color: white;
      border: 1px solid #e0e0e0;
      border-radius: var(--border-radius);
      padding: 20px;
      z-index: 1000;
      bottom: 0px;
      right: 0px;
      opacity: 1;
      animation: zoomFadeInAnimation 600ms;
      //animation-fill-mode: backwards;
      
      p {
        margin-top: 0;
      }
      @include for-phone-only{
        left: 0;
        right: 0;
        bottom: 30px;
        min-width: 300px;
        width: 80vw;
    }
      button {
        margin: 5px;
      }
      .dialog-footer{
        text-align: right;
      }
    }
    &.confirm-open{
        &::after {
            content: "";
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 999;
        }
    }
  }