.locale-selector {
    .btn-locale {
      border: none;
      padding: 10px 8px;
      position: relative;
      background: none;
      &:before{
        display: block;
        width: 25px;
        height: 25px;
        position: relative;
        z-index: 2;
      }
      &:active{
        box-shadow: none;
        -webkit-appearance: none;
        outline: none;
      }
      &.empty{
        opacity: 0.4;
      }
      filter: grayscale(1);
      transition: all 0.3s;
      &.active, &:focus{
        opacity: 1;
        scale: 1.2;
        filter: grayscale(0);
        box-shadow: none;
      }
    }
    .lang{
      &-en {
        &:before{
          content: url(images/UK.svg);
        }    
      }
      &-sv {
        &:before{
          content: url(images/SE.svg);
        }
      }
      &-fi {
        &:before{
          content: url(images/FI.svg);
        }
      }
      &-no {
        &:before{
          content: url(images/NO.svg);
        }
      }
    }
  }