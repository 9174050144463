.search-result-container{
    @include for-tablet-portrait-up{
        display: table;
        width: 100%;
        border-collapse: collapse;
        border-radius: var(--border-radius);
        //overflow: hidden;

        .search-result-header {
            display: table-header-group;
            font-weight: bold;
            padding: 10px;
            border-bottom: 1px solid lightgray;
            background: var(--color-primary);
            color:var(--color-primary-contrast);
            > span{
                display: table-cell;
                padding: 10px;
                &:last-child{
                    text-align: right;
                }
            }
        }
        .search-result {
            display: table-row-group;
            & > li {
                display: table-row;
                
                > *{
                    display: table-cell;
                    padding: 5px 10px;
                    vertical-align: middle;

                    &:first-child{
                        min-width: 250px;
                    }
                    &:last-child{
                        text-align: right;
                    }
                }
                .btn-group{
                    white-space: nowrap;
                }
            }
            .bage {
                display: inline-block;
            }
        }

    }
    
    .search-result > li {
        background-color: white;        
        > * {
            &:first-child{
                font-weight: bold
            }
        }
        
    }
    .search-result {
        & > li {
            border-top: 1px solid lightgray;
            padding: 5px 10px;
            &:first-child {
                border-top: none;
            }
        }
    }
    .item-name{
        display: none;
    }
    @include for-phone-only{
        .search-result-header{
            display: none;
        }

        .search-result > li {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            margin-bottom: 10px;
            padding: 10px;
            border-radius: var(--border-radius);
            border: none;
            span{
                word-break: break-all;
            }
            > *{
                &:empty{
                    display: none;
                }
                &:first-child{
                    grid-column: 1 / -1;
                }
            }
            .btn-group{
                grid-column: 1 / -1;
            }
        }
        .item-name{
            display: block;
            font-size: 0.8rem;
            font-weight: bold;
            opacity: 0.6;
            &:after{
                content: ":";
            }
        }
    }

}

.list{
    list-style: none;
    border-top: 1px solid lightgray;
    >li {
        padding: 10px;
        border-bottom: 1px solid lightgray;
        &.selected{
            @include icon(check){
                color: var(--color-success);
            };
        }
    }
   }