.toast-container{
    //position: absolute;
    //top: 0;
    .toast{
        opacity: 0;
        transition: opacity 0.5s;
        &.show{
            opacity: 1;
        }
    }
    .toast-body{
        display: flex;
        align-items: center;
        gap: 10px;
        border: 2px solid var(--color-success);
        padding: 3px;
        border-radius: 20px;
    }
    
    .checkmark2-wrapper{
        //height:100vh;
        display:flex;
        justify-content:center;
        align-items:center;
        //background-color:#eee
        //scale: 0.5;
    }
    .checkmark__circle{
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: var(--color-success);
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
    }
    .toast-text{
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        //height: 2ch;
        animation: text 0.6s 1.2s cubic-bezier(0.65, 0, 0.45, 1) forwards
    }
    .checkmark2{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;
        stroke-width: 3;
        stroke: #fff;
        stroke-miterlimit: 10;
        margin: 10% auto;
        box-shadow: inset 0px 0px 0px #7ac142;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
    }

        .checkmark__check{
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
        }
        
}
@keyframes stroke{
    100%{
    stroke-dashoffset: 0
    }
}
@keyframes scale{
    0%, 100%{
        transform: none
    }50%{
        transform: scale3d(1.1, 1.1, 1)
    }  
}
@keyframes fill{
    100%{
        box-shadow: inset 0px 0px 0px 30px #7ac142
    }
}
@keyframes text{
    100%{
        width: 250px;
    }
}