.admin-page{
    
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    h1 {
        flex: 1 100%;
    }
    .user-list-item{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        .badge {
            margin: 0;
        }
        .search{
            
        }
    }
    .search {
        flex: 1 60%;
        margin-bottom: var(--gutter);
        > * {
            margin: 10px 0;
        }
    }
    .user-profitcenter-access{
        min-width: 250px;
    }
}
